import React, { Component } from 'react';

class Landing extends Component {
    render() {
        return (
            <div className="landing-div">
                Welcome. 
                <br/>
                My name is Giselle Sanchez, a software developer based in NYC.
                <br/>
            </div>
        )
    }
}
export default Landing;